<!-- 志愿者团队详情弹窗页面 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="closeModal"
  >
    <!-- 基本信息 -->
    <a-descriptions  style="
        word-break: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
      "
      layout="vertical" >
      <a-descriptions-item label="团队名称">
        {{ personInforList.groupName ? personInforList.groupName : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="团长姓名">
        {{
          personInforList.volunteerName
            ? personInforList.volunteerName
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="联系方式" >
        {{ personInforList.contactWay }}
      </a-descriptions-item>
      <a-descriptions-item label="所属网格">
        {{ personInforList.gridName ? personInforList.gridName : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="详细地址" :span="2">
        {{ personInforList.address ? personInforList.address : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="团队介绍" :span="3">
        {{ personInforList.groupIntroduce }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="auditStatus === '2'"
        label="申请原因"
        :span="3"
      >
        {{ personInforList.applyReason }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="auditStatus === '2'"
        label="拒绝原因"
        :span="3"
      >
        {{ personInforList.rejectReason }}
      </a-descriptions-item>
    </a-descriptions>
    <!-- 团队成员 -->
    <div
      v-if="auditStatus === '1'"
      style="
        border-left: 4px solid #2A5CFF;
        font-size: 0.08rem;
        font-weight: bold;
        color: black;
        line-height: 100%;
        margin-bottom: 15px;
      "
    >
      &nbsp;团队成员
    </div>
    <ax-table
      v-if="auditStatus === '1'"
      ref="tableBox"
      :columns="columns"
      :show-search="false"
      :showToolBar="false"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
   
    >
    </ax-table>
  </el-dialog>
</template>
<script>
import api from "./api";
// import imagePreview from "./image-preview/imagePreview";
export default {
  components: {
    // imagePreview,
  },
  data() {
    return {
      api,
      title: "",
      visible: false,
      businessId: "",
      disabled: false,
      flag: "",
      fileIds: [], //图片数组
      personInforList: {},
      nameList: [],
      personTableData: [],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 70,
          },
          {
            title: "联系方式",
            dataIndex: "contactWay",
            ellipsis: true,
            width: 180,
          },
          {
            title: "入团时间",
            dataIndex: "createTime",
            ellipsis: true,
            width: 130,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 130,
          },
        ],
        true //去掉序号
      ),
      dataSourceApis: api.getVolunteerGroupDetail,
      dataSourceParams: {
        groupId: "",
        auditStatus:1
      },
      personInforList: {},
      volunteerInforList: {},
      auditStatus: "",
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  computed: {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
      this.title = record.title;
      this.businessId = record.id;
      this.dataSourceParams.groupId = this.businessId;
      this.getVolunteerGroupInfo(record.id);
    },
    // 取消
    closeModal() {
      this.businessId = "";
      this.auditStatus = ''
      this.visible = false;
      this.$emit("refsh");
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "watch":
          this.visible = true;
          break;
        // 详情
        case "del":
          break;
      }
    },
    watch(record) {
      console.log(record, "257");
      this.openModal(record);
    },
    async getVolunteerGroupInfo(id) {
      const res = await api.getVolunteerGroupById(id);
      this.personInforList = res.data;
      this.auditStatus = res.data.auditStatus;
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2A5CFF;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
</style>