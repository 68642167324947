<!-- 志愿者团队审核弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 基本信息 -->
      <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;">
        <a-descriptions-item label="团队名称" :span="3">
          {{ personInforList.groupName ? personInforList.groupName : "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="团长姓名">
          {{
            personInforList.volunteerName
              ? personInforList.volunteerName
              : "--"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="联系方式" :span="2">
          {{ personInforList.contactWay }}
        </a-descriptions-item>
        <a-descriptions-item label="所属网格">
          {{ personInforList.gridName ? personInforList.gridName : "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="详细地址" :span="2">
          {{ personInforList.address ? personInforList.address : "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="团队介绍" :span="3">
          {{ personInforList.groupIntroduce }}
        </a-descriptions-item>
        <a-descriptions-item label="申请原因" :span="3">
          {{ personInforList.applyReason }}
        </a-descriptions-item>
      </a-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          style="background-color: #556bff"
          :loading="saveType"
          >同 意</el-button
        >
        <el-button @click="onCancel">拒 绝</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :z-index="20"
      title="拒绝理由"
      :visible.sync="visible1"
      width="40%"
      @close="closeModal1"
      :close-on-click-modal="false"
    >
      <el-input
        type="textarea"
        placeholder="请输入"
        v-model="rejectReason"
        maxlength="200"
        show-word-limit
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "./api";
export default {
  components: {},
  data() {
    return {
      visible: false,
      visible1: false,
      id: "",
      title: "团队审核",
      saveType: false,
      rejectReason: "", //拒绝理由
      personInforList: {},
    };
  },
  watch: {},
  computed: {},
  async mounted() {},
  methods: {
    // 打开弹窗
    openModal({ record }) {
      this.visible = true;
      this.saveType = false;
      this.id = record.id;
      this.getVolunteerGroupInfo(record.id);
    },
    async getVolunteerGroupInfo(id) {
      const res = await api.getVolunteerGroupById(id);
      this.personInforList = res.data;
    },
    // 关闭弹窗
    closeModal() {
      this.businessId = "";
      this.visible = false;
      this.$emit("refsh");
    },
    closeModal1() {
      this.visible1 = false;
      this.rejectReason = "";
      this.$emit("refsh");
    },
    // 同意 (审核通过)
    async onSubmit() {
      this.saveType = true;
      const volunteerInfo = {
        id: this.id,
        auditStatus: "1", //审核通过
      };
      const res = await api.auditVolunteerGroup(volunteerInfo);
      console.log(res, "307");
      if (res.status === 200) {
        this.saveType = false;
        this.$message.success("审核通过");
        this.closeModal1();
        this.closeModal();
      } else {
        this.saveType = false;
        this.$message.success("审核失败");
      }
    },
    // 拒绝  (审核未通过)
    onCancel() {
      this.visible1 = true;
    },
    // 审核不通过(拒绝)
    async sure() {
      this.saveType = true;
      const volunteerInfo = {
        id: this.id,
        auditStatus: "2",
        rejectReason: this.rejectReason,
      };
      const res = await api.auditVolunteerGroup(volunteerInfo);
      console.log(res, "307");
      if (res.status === 200) {
        this.saveType = false;
        this.$message.success("审核未通过,请在小程序重新发起申请");
        this.closeModal1();
        this.closeModal();
      } else {
        this.saveType = false;
        this.$message.success("审核失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}

.search-title {
  font-weight: 800;
}
</style>