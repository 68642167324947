<!-- 志愿者团队新增弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <ax-form ref="form" :formBuilder="formBuilder" @change="leaderNameChange">
        <!-- 以下方法是给input添加校验的方法 -->
        <div slot="residentName" slot-scope="{ record }" style="display: flex">
          <a-input
            disabled
            v-decorator="[
              record.model,
              {
                rules: record.rules,
              },
            ]"
            placeholder="请输入"
          ></a-input>
          <ax-button @click="chooseTeamLeader" style="margin-left: 2%"
            >选择人员</ax-button
          >
        </div>
        <!-- <div slot="teamIntroduction">
          <editor :content="content" @onChange="handleEditorChange" />
        </div> -->
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          style="background-color: #556bff"
          :loading="saveType"
          >保 存</el-button
        >
        <el-button @click="onCancel">取 消</el-button>
      </span>
    </el-dialog>
    <choose-team-leader
      ref="chooseTeamLeader"
      @choose="receiveTeamLeaderId"
    ></choose-team-leader>
  </div>
</template>
<script>
const formList = [
  {
    label: "团队名称",
    type: "",
    model: "groupName",
    options: { allowClear: true, placeholder: "请输入...", maxLength: 20 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入姓名" }],
  },
  {
    label: "团长姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入..." },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择团长" }],
  },
  {
    label: "联系方式",
    type: "",
    model: "contactWay",
    options: { placeholder: "请输入", maxLength: 11 ,disabled:true },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern:
          /^134[0-8]\d{7}$|^13[^4]\d{8}$|^14[5-9]\d{8}$|^15[^4]\d{8}$|^16[6]\d{8}$|^17[0-8]\d{8}$|^18[\d]{9}$|^19[8,9]\d{8}$/,
        message: "请输入正确格式的联系方式",
      },
    ],
  },
  {
    label: "所属网格",
    type: "select",
    model: "gridName",
    options: { disabled: true, placeholder: "请选择团长" },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "请选择团长",
      },
    ],
  },
  {
    label: "详细地址",
    type: "",
    model: "address",
    options: {
      disabled: false,
      placeholder: "请输入",
      maxLength: 50,
    },
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "团队介绍",
    type: "textarea",
    model: "groupIntroduce",
    options: {
      disabled: false,
      placeholder: "请输入",
      minRows: 4,
      maxLength: 200,
    },
    col: { span: 24 },
    rules: [{ required: false, message: "请输入" }],
  },
];
import api from "./api";
import ChooseTeamLeader from "./chooseTeamLeader.vue";
// import editor from "@/components/editor/wangeditor.vue";
export default {
  components: { ChooseTeamLeader },
  data() {
    return {
      visible: false,
      id: "",
      title: "新建团队",
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      leaderName: "", //团长姓
      volunteerId: "", //团长id
      content: "", //富文本
      msgContent: "", //富文本内容
    };
  },
  watch: {},
  computed: {},
  async mounted() {},
  methods: {
    // 打开弹窗
    openModal() {
      this.visible = true;
      this.saveType = false;
    },
    leaderNameChange(e, type) {
      // console.log(e, type);
    },
    // 打开选择团长弹窗
    chooseTeamLeader() {
      this.$refs.chooseTeamLeader.openModal();
    },
    receiveTeamLeaderId(item) {
      console.log(item, "398");
      if (item.id) {
        // this.leaderName = item.residentName;
        this.$refs.form.setFieldsValue({
          residentName: item.residentName,
          contactWay: item.contactWay,
          gridName: item.gridName, //所属网格
        });
        this.volunteerId = item.id;
      }
    },
    // 富文本编辑器内容变化触发
    // handleEditorChange(html) {
    //   this.msgContent = html;
    //   console.log(html, "161");
    // },
    // 关闭弹窗
    closeModal() {
      this.businessId = "";
      this.$refs.form.resetFields()
      this.visible = false;
      this.$emit("refsh");
    },
    // 提交
    async onSubmit() {
      this.saveType = true
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        value.volunteerId = this.volunteerId;
        value.auditStatus = "1";
        delete value.residentName;
        delete value.gridName;
        console.log(value, "256");
        const res = await api.addVolunteerGroup(value);
        console.log(res);
        if (res.status === 200) {
          this.saveType = false
          this.$message.success("志愿者团队新增成功");
          this.closeModal();
        } else {
          this.saveType = false
          this.$message.error("志愿者团队新增失败");
        }
      });
    },
    onCancel() {
      this.visible = false;
    },
    // 根据id获取详情
    // async getPersonInfo(id) {
    //   const res = await api.getResidentArchivesById(id);
    //   console.log(res.data, "594");
    //   this.$refs.form.setFieldsValue(res.data);
    //   this.$refs.form1.setFieldsValue(res.data);
    //   this.$refs.form2.setFieldsValue(res.data);
    //   this.nativePlace = res.data.nativePlace;
    //   this.buildingNum = res.data.buildingNum;
    //   this.unitNum = res.data.unitNum;
    //   this.floorNum = res.data.floorNum;
    //   this.accountNum = res.data.accountNum;
    //   this.houseHolderCard = res.data.houseHolderCard;
    //   this.houseHolderRelation = res.data.houseHolderRelation;
    //   if (res.data.cellName) {
    //     this.detailedAddress = `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`;
    //   } else {
    //     this.detailedAddress = "";
    //   }
    //   this.flag = res.data.flag;
    //   this.currentArea = res.data.currentArea;
    //   this.nativeCode = res.data.nativeCode;
    //   this.currentAreaCode = res.data.currentAreaCode;
    //   this.residenceCode = res.data.residenceCode;
    //   this.residencePlace = res.data.residencePlace;
    //   this.cellName = res.data.cellName;
    //   this.calculateIDCardInfo(res.data.identityCard);
    //   if (res.data.flag === "0") {
    //     this.$refs.form2.setOptions(
    //       ["houseHolderCard", "houseHolderRelation"],
    //       "hide",
    //       true
    //     );
    //   } else {
    //     this.$refs.form2.setFieldsValue({
    //       houseHolderCard: res.data.houseHolderCard,
    //     });
    //     this.$refs.form2.setFieldsValue({
    //       houseHolderRelation: res.data.houseHolderRelation,
    //     });
    //     this.$refs.form2.setOptions(["houseHolderCard"], "hide", false);
    //     this.$refs.form2.setOptions(["houseHolderRelation"], "hide", false);
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}

.search-title {
  font-weight: 800;
}
</style>